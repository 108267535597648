
































































































import CreditCardForm from '@/components/payments/CreditCardForm.vue';
import GetSonyPaymentToken from '@/components/payments/GetSonyPaymentToken.vue';
import SavedCardForm from '@/components/payments/SavedCardForm.vue';
import { Component, Vue } from 'vue-property-decorator';
import { SubscriptionRepository } from '@/repositories/SubscriptionRepository';
import { SubscriptionInfo } from '@/models/Subscription';
import { InputCard } from '@/models/InputCard';
@Component({
  components: {
    CreditCardForm,
    SavedCardForm,
    GetSonyPaymentToken
  }
})
export default class P0227 extends Vue {
  // クレジットカード情報（表示用）
  subscriptionInfo = {} as SubscriptionInfo;
  // 入力されたクレジットカード情報
  inputCard = {} as InputCard;
  // カードトークン
  token = '';
  buttonLoading = false;
  // クレジットカード更新失敗時エラーメッセージ
  errorMsg = '';
  $refs!: {
    CreditCardForm: CreditCardForm;
    GetSonyPaymentToken: GetSonyPaymentToken;
  };

  get subscriptionRepo() {
    return new SubscriptionRepository();
  }

  async created() {
    await this.initialize();
  }

  async initialize() {
    await this.getRegisterInfo();
  }
  // サブスク登録情報を取得する
  async getRegisterInfo() {
    this.subscriptionRepo
      .getSubscriptionInfo()
      .then(res => {
        this.subscriptionInfo = res;
      })
      .catch((errCode: number) => {
        this.handleGetRegisterInfoErr(errCode);
      });
  }

  handleGetRegisterInfoErr(errCode: number) {
    switch (errCode) {
      case 42200:
        this.errorMsg = this.$msg.get('2000074', {
          errorCode: errCode
        });
        break;
      case 42206:
        this.$router.push({ name: 'manage-subscription' });
        break;
      case 50000:
        this.errorMsg = this.$msg.get('2000071', {
          errorCode: errCode
        });
        break;
      case 50001:
        this.errorMsg = this.$msg.get('2000071', {
          errorCode: errCode
        });
        break;
      default:
        this.errorMsg = this.$msg.get('2000078');
        break;
    }
  }

  getPaymentToken() {
    this.buttonLoading = true;
    this.$refs.CreditCardForm.$validator.validateAll().then(valid => {
      if (valid) {
        this.$refs.GetSonyPaymentToken.getPaymentToken(
          this.inputCard.number,
          this.inputCard.expYear,
          this.inputCard.expMonth,
          this.inputCard.securityCode
        );
      } else {
        this.buttonLoading = false;
      }
    });
  }

  handleToken(token: string, err: any) {
    if (err) {
      this.errorMsg = this.$msg.get('2000075');
      this.buttonLoading = false;
    } else {
      this.token = token;
      this.changeCardInfo();
    }
  }

  changeCardInfo() {
    this.subscriptionRepo
      .updateSubscriptionCard(this.token)
      .then(() => {
        // 変更完了画面に遷移
        this.$router.push({
          name: 'change-subscription-creditcard-complete'
        });
      })
      .catch((errCode: number) => {
        this.handleUpdateErr(errCode);
      })
      .finally(() => {
        this.buttonLoading = false;
      });
  }

  handleUpdateErr(errCode: number) {
    switch (errCode) {
      case 42200:
        this.errorMsg = this.$msg.get('2000074', {
          errorCode: errCode
        });
        break;
      case 42201:
        this.errorMsg = this.$msg.get('2000070', {
          errorCode: errCode
        });
        break;
      case 42203:
        this.errorMsg = this.$msg.get('2000070', {
          errorCode: errCode
        });
        break;
      case 42206:
        this.errorMsg = this.$msg.get('2000074', {
          errorCode: errCode
        });
        break;
      case 42210:
        this.errorMsg = this.$msg.get('2000074', {
          errorCode: errCode
        });
        break;
      case 50000:
        this.errorMsg = this.$msg.get('2000071', {
          errorCode: errCode
        });
        break;
      case 50001:
        this.errorMsg = this.$msg.get('2000071', {
          errorCode: errCode
        });
        break;
      default:
        this.errorMsg = this.$msg.get('2000075');
        break;
    }
  }
}
